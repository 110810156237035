import React, { useMemo, useCallback } from 'react';
import classnames from 'classnames';
import useTracking from 'components/Globals/Analytics';
import ImpressionTracker from 'utils/components/impressionTracker';

import EntityName from 'components/Globals/EntityName';
import LinkButton from 'components/uiLibrary/LinkButton';
import Typography from 'components/uiLibrary/Typography';
import ImageAvatar from 'components/uiLibrary/ImageAvatar';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Image, { TRANSFORMATIONS } from 'components/uiLibrary/Image';
import MapView, { MARKER_SIZES } from 'components/Globals/MapView';

import usePageContext from 'utils/hooks/usePageContext';
import { getLocation } from 'utils/globals';
import { getPrimaryProfession } from 'utils/artists';
import { getMediaSource } from 'utils/media';

import { TRACK_EVENTS, ENTITY_CARD_CLICK } from 'utils/tracking';
import { TP } from 'constants/index';
import { ENTITY_TYPES } from 'operabase-router/constants';

import classes from './PreviewCard.module.scss';
import { GOOGLE_OLD_TRACKING_SERVICES } from '../Analytics/constants';

export const SIZE = {
  LARGE: 'large',
  SMALL: 'small',
};

export const VARIANTS = {
  PORTRAIT: 'portrait',
  LANDSCAPE: 'landscape',
};

const DIMENSIONS = {
  [VARIANTS.LANDSCAPE]: {
    [SIZE.SMALL]: {
      height: '64',
    },
    [SIZE.LARGE]: {
      height: '161.32',
    },
  },
  [VARIANTS.PORTRAIT]: {
    [SIZE.LARGE]: {
      height: '220.5',
    },
    [SIZE.SMALL]: {
      height: '168.65',
    },
  },
};

const EntityTags = ({ tag, subTag }) => {
  if (!tag && !subTag) {
    return null;
  }
  return (
    <div className={classes.card__img_tagsContainer}>
      {tag && (
        <Typography size={11} weight="medium" textTransform="uppercase" className={classes.card__img_tag}>
          {tag}
        </Typography>
      )}
      {subTag && (
        <div className={classnames(classes.card__img_subtagContainer, classes.card__img_tag)}>
          <Typography size={10} className={classnames(classes.card__img_subtag)}>
            {subTag}
          </Typography>
        </div>
      )}
    </div>
  );
};

// TODO: FB-32730 Refactor PreviewCard
const PreviewCard = ({
  entity,
  entityType,
  openNewTab,
  isLink = true,
  variant = VARIANTS.PORTRAIT,
  trackingData,
  size = SIZE.SMALL,
  primaryProfession,
  preloadImage = false,
}) => {
  const { navigate } = usePageContext();
  const track = useTracking();
  const linkProps = navigate.getLinkProps({ entityType, entity, onlyLinkProps: true, rel: 'nofollow' });

  const isMapShown = useMemo(() => entity?.lat && entity?.lon, [entity?.lat, entity?.lon]);

  const handleOnClick = useCallback(() => {
    track.click(
      {
        name: TRACK_EVENTS.CLICK,
        id: ENTITY_CARD_CLICK(ENTITY_TYPES.PROFILE),
      },
      GOOGLE_OLD_TRACKING_SERVICES,
    );
  }, [track]);

  const imgProps = useMemo(
    () => ({
      transformations: {
        ...(entityType === ENTITY_TYPES.PROFILE ? TRANSFORMATIONS.PROFILE_IMAGE : TRANSFORMATIONS.ORG_LOGO),
        ...(variant === VARIANTS.LANDSCAPE && { radius: 6 }),
      },
      disableNextImage: true,
      useIntersectionObserver: true,
      alt: entity?.name,
      title: entity?.name,
      preload: preloadImage,
      ...DIMENSIONS[variant]?.[size],
    }),
    [entityType, entity?.name, size, variant, preloadImage],
  );

  const src = getMediaSource({
    id: entity?.id,
    file: { urls: entity?.image || entity?.logo },
  });

  const { subTitle, title, tag, subTag } = useMemo(() => {
    switch (entityType) {
      case ENTITY_TYPES.PROFILE: {
        return {
          label: `${TP}.m_ARTIST`,
          subTitle: entity?.headline ? entity?.headline : getPrimaryProfession(entity, primaryProfession),
        };
      }
      case ENTITY_TYPES.ORGANIZATION: {
        return {
          tag: entity?.organizationType?.name,
          subTitle: getLocation(entity),
        };
      }
      default: {
        return {};
      }
    }
  }, [entity, entityType, primaryProfession]);

  let content;
  if (src) {
    content = <Image src={src} {...imgProps} />;
  } else if (isMapShown) {
    content = (
      <MapView
        latitude={entity?.lat}
        longitude={entity?.lon}
        label={entity?.name}
        width={imgProps?.width}
        height={imgProps?.height}
        zoom={10}
        markerSize={MARKER_SIZES.TINY}
        withoutLink
      />
    );
  } else {
    content = (
      <ImageAvatar
        type="V4"
        name={entity?.name}
        fontSize={size === SIZE.LARGE ? 44 : 22}
        shape="rect"
        width="100%"
        height="100%"
      />
    );
  }

  const organisationType = entity?.organizationType?.slug;
  const completeTrackingData = {
    ...trackingData,
    entityId: entity?.id,
    entityName: entity?.name,
    entityType,
    meta: {
      ...(entity?.source_tag && { sourceTag: entity.source_tag }),
      ...(organisationType && { organisationType }),
      ...trackingData?.meta,
    },
  };

  return (
    <LinkButton
      styles={{
        root: classnames(classes.link, {
          [classes.withoutLink]: !isLink,
          [classes[`link__variant_${variant}`]]: !![classes[`link__variant_${variant}`]],
        }),
      }}
      variant="native"
      title={title}
      isLink={isLink}
      trackingData={completeTrackingData}
      {...linkProps}
      {...(openNewTab && { target: '_blank' })}
      {...(ENTITY_TYPES.PROFILE === entityType && { onClick: handleOnClick })}
    >
      <ImpressionTracker data={completeTrackingData}>
        <div
          className={classnames(classes.card, {
            [classes[`card__variant_${variant}`]]: !![classes[`card__variant_${variant}`]],
            [classes[`card__size_${size}`]]: !![classes[`card__size_${size}`]],
          })}
        >
          <div className={classes.card__img}>
            {content}
            {variant !== VARIANTS.LANDSCAPE && size !== SIZE.SMALL && <EntityTags tag={tag} subTag={subTag} />}
          </div>
          <div className={classes.card__textWrapper}>
            <Typography weight="bold" className={classes.title} size={12}>
              <EntityName entityType={entityType} entity={entity} isRaw />
            </Typography>
            {subTitle && (
              <Typography size={11} className={classes.subtitle} color="secondary" truncate>
                {subTitle}
              </Typography>
            )}
          </div>
          <div className={classes.card__chevron}>
            <SpriteIcon icon="chevron_right" className={classes.icon} size={16} />
          </div>
        </div>
      </ImpressionTracker>
    </LinkButton>
  );
};

export default PreviewCard;
